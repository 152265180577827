// Monosten

@font-face {
  font-family: 'Monosten Circ';
  src: url('../fonts/Monosten_C.otf');
  font-style: normal;
}

@font-face {
  font-family: 'Monosten';
  src: url('../fonts/Monosten_Reg.otf');
  font-style: normal;
}


// Monument

@font-face {
  font-family: 'Monument';
  src: url('../fonts/MonumentExtended-Regular.otf');
  font-style: normal;
}


/*
		font(
			
		$style: normal,
		$variant: normal,
		$weight: 400,
		$size: 1em,
		$line-height: 1.2em,
		$font: "Riforma",
		$color: #000
		
		)
*/

h1,
h2,
h3,
h4,
h5,
h6 {
	@include font(
		$weight: 500,
	);
	margin-bottom: 0px;
}

p {
	
	@include font(
		$font: 'Helvetica-Neue',
		$line-height: 1.8em
	);
	letter-spacing: .02em;
	
}