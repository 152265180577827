.o-flexLayout {
	
	padding: 6rem $global-gutter;
	
}


.-bg {
	@each $name, $color in $colors {
		&-#{$name} {
			background: $color;
		}
	}
}