.a-aboutImage {
	
	&__one {
		display: none;
		position: absolute;
			bottom: 0;
			right: 0;
		z-index: 2;
		width: 45%;
		transform: translateY(50%);
	}
	
	&__two {
		position: absolute;
			bottom: 0;
			left: 0;
		z-index: 2;
		width: 40%;
		transform: translateY(10%);
	}  
	
}

.a-aboutArrow {
	position: absolute;
		top: -2rem;
		left: $global-gutter;
	z-index: 3;
}

@media only screen and (min-width: 640px) {
	
	.a-aboutImage {
		
		&__one {
			display: inline-block;
		}
		
	}
	
}