.o-banner {
	
	&__hero {
		height: 100vh;
			max-height: 600px;
			min-height: 350px;
		position: relative;
		
		&.-first {
			padding-top: 8rem;
		}
		&.-image {
			height: 65vh;
		}
		&.-padded {
			padding: 3rem 5vw;
		}
	}
	
}

.m-banner {
	
	&__content {
		position: relative;
			z-index: 3;
		padding: 3rem $global-gutter;
		
		&.-light-pad {
			padding: 3rem 5vw;
		}
	}
	
	&__scrollButton {
		margin-top: 2rem;
	}
	
	&__headline {
		position: relative;
		z-index: 3;
	}
	
	&__box {
		background: $green;
		position: relative;
		z-index: 3;
		padding: 3rem;
	}
	
}

.a-banner {
	
	&__bgImage {
		position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
		height: 100%;
		width: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&__overlay {
		z-index: 2;
		position: absolute;
			top: 0;
			left: 0;
		width: 100%;
		height: 100%;
		background: #000;
		opacity: .5;
		
		&.-light {
			opacity: .2;
		}
	}
	
}

@media only screen and (min-width: 640px) {
	
	.o-banner {
		&__hero {
			max-height: 788px;
			min-height: 450px;
		}
	}
	
}

@media only screen and (min-width: 1024px) {
	
	.o-banner {
		&__hero {
			max-height: 1080px;
			min-height: 750px;
			
			&.-image {
				min-height: 450px;
			}
		}
	}
	
}