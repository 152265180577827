/*
		font(
			
		$style: normal,
		$variant: normal,
		$weight: 400,
		$size: 1em,
		$line-height: 1.2em,
		$font: "Helvetica-Neue",
		$color: #000
		
		)
*/

h1 {
	@include font(
		
		$font: 'Monument',
		$size: 5.875rem,
		$line-height: 1em,
		
	);
	letter-spacing: .096em;
	text-transform: uppercase;
}
h2 {
	@include font(
			
		$font: 'Monument',
		$size: 3.375rem,
		$line-height: 1.2em,
		
	);
	letter-spacing: .096em;
	text-transform: uppercase;
}
h3 {
	@include font(
		
		$font: 'Monument',
		$size: 2.25rem,
		$line-height: 1.2em,
		
	);
	letter-spacing: .096em;
	text-transform: uppercase;
}
h4 {
	@include font(
		
		$font: 'Monument',
		$size: 1.625rem,
		$line-height: 1.2em,
		
	);
	letter-spacing: .096em;
	text-transform: uppercase;
}
h5 {
	@include font(
		
		$font: 'Monosten',
		$size: 1.25rem,
		$line-height: 1.3em,
		
	);
	letter-spacing: .096em;
	text-transform: uppercase;
}
p,
a,
button {
	@include font(
			
		$font: 'Monosten',
		$size: 1.05rem,
		$line-height: 1.5em,
		
	);
	letter-spacing: .096em;
}


//
//
//	------
//	Headers
//	------
//
//

.a-header {
	
	&__one {
		@include font(
			
			$font: 'Monument',
			$size: 5.875rem,
			$line-height: 1em,
			
		);
		letter-spacing: .096em;
		text-transform: uppercase;
		
		&.-large {
			font-size: 5.5vw;
		}
	}
	
	
	&__two {
		@include font(
			
			$font: 'Monument',
			$size: 3.375rem,
			$line-height: 1.2em,
			
		);
		letter-spacing: .096em;
		text-transform: uppercase;
	}
	
	&__three {
		@include font(
			
			$font: 'Monument',
			$size: 2.25rem,
			$line-height: 1.2em,
			
		);
		letter-spacing: .096em;
		text-transform: uppercase;
	}
	
	&__four {
		@include font(
			
			$font: 'Monument',
			$size: 1.625rem,
			$line-height: 1.2em,
			
		);
		letter-spacing: .096em;
		text-transform: uppercase;
		
		&.-link {
			
			position: relative;
			padding-bottom: .2rem;
			
			&:after {
				display: block;
				content: "";
				position: absolute;
					bottom: 0;
					left: 0;
				width: 100%;
				height: 2px;
				background: #000;
				opacity: 0;
				transform: translateY(5px);
				transition: all .2s ease-out;
			}
			
			&:hover {
				color: $black;
				
				&:after {
					transform: translateY(0px);
					opacity: 1;
				}
			}
		}
	}
	
	&__five {
		@include font(
			
			$font: 'Monosten',
			$size: 1.25rem,
			$line-height: 1.3em,
			
		);
		letter-spacing: .096em;
		text-transform: uppercase;
	}
	
	&__scroll {
		@include font(
			
			$font: 'Monosten',
			$size: 1.0rem,
			$line-height: 1.5em,
			$color: $white
			
		);
		letter-spacing: .096em;
		margin-bottom: 1.5rem;
	}
	
}


//
//
//	------
//	Copy
//	------
//
//

.a-copy {
	
	&__body {
		@include font(
			
			$font: 'Monosten',
			$size: 1.05rem,
			$line-height: 1.5em,
			
		);
		letter-spacing: .096em;
		
		&.-link {
			
			position: relative;
			padding-bottom: .2rem;
			
			&:after {
				display: block;
				content: "";
				position: absolute;
					bottom: 0;
					left: 0;
				width: 100%;
				height: 2px;
				background: #000;
				opacity: 0;
				transform: translateY(5px);
				transition: all .2s ease-out;
			}
			
			&:hover {
				color: $black;
				
				&:after {
					transform: translateY(0px);
					opacity: 1;
				}
			}
		}
	}
	
}

@include colors();

.-bold {
	font-weight: 700;
	line-height: 2em;
}

.-underline {
	position: relative;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	
	&:after {
		display: block;
		content: "";
		position: absolute;
			bottom: 0;
			left: 0;
		width: 100%;
		height: 2px;
		background: $black;
	}
}

.unordered-list {
	@include font(
		
		$size: 1.5rem,
		$line-height: 1.1em,
		$weight: 700
		
	);
	list-style-type: disc;
	
	& > li {
		margin-bottom: 1.5rem;
	}
}

@media only screen and (min-width: 640px) {
	
	.-vert {
		writing-mode: vertical-rl;
	}
	
}
