.o-mainMenu {
	
	position: fixed;
		top: 0;
		left: 0;
	z-index: 199;
	width: 100%;
	height: 100%;
	background: $black;
	margin: 0px;
	padding: 3rem $global-gutter;
	padding-top: 12rem;
	transform: translateY(-150%);
	transition: all .6s $easeOutCubic;
	
	& > .a-link__nav {
		display: block;
		font-size: 1.5rem;
		margin-bottom: 1.2rem;
	}
	
	&.-is-open {
		transform: translateY(0%);
	}
	
}

.m-mainMenu {}

.a-mainMenu {}