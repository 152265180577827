.o-slideshow {
	
	&__text {
		background: $black;
		padding: 5rem 5vw;
		position: relative;
		
		&.-bgImage {
			background-color: transparent;
		}
	}
	
	&__textContainer {
		position: relative;
		border-top: 2px solid #fff;
		border-bottom: 2px solid #fff;
		padding: 2rem 0rem;
		padding-bottom: 6rem;
		z-index: 2;
	}
	
}

.m-slideshow {}


.o-slide {}

.m-slide {
	
}

.a-slideshow {
	
	&__overlay {
		position: absolute;
			top: 0;
			left: 0;
		width: 100%;
		height: 100%;
		background: #000;
		opacity: .5;
	}
	
}



//
//
// BX Slide Style Settings
//
//

.bx-controls {
	position: absolute;
		left: 0;
		bottom: 2rem;
	z-index: 5;
}
.bx-viewport {
	z-index: 4;
}

.bx-pager-item {
	display: inline-block;
	padding-right: 1.2rem;
}

.bx-pager-link {
	border: 2px solid $white;
	transition: all .2s ease-out;
	color: transparent;
	display: inline-block;
	width: 25px;
	height: 25px;
	
	&:hover {
		background: #444;
		color: transparent;
	}
	
	&.active {
		background: #7E7E7E;
		color: transparent;
	}
}


@media only screen and (min-width: 640px) {
	
	.o-slideshow {
		
		&__textContainer {
			padding-bottom: 3rem;
		}
		
	}
	
	.bx-controls {
		left: auto;
		right: 0;
	}
	.bx-pager-item {
		padding-left: 1.2rem;
		padding-right: 0rem;
	}
	
}