.o-cta {
	
	position: relative;
	
}

.m-cta {
	
	&__headlineContainer {
		position: relative;
		z-index: 2;
	}
	
	&__testimonialContainer {
		position: relative;
		z-index: 4;
		width: 75%;
		margin-left: 25%;
		margin-bottom: -4rem;
		margin-top: 3rem;
		background: $green;
		padding: 2rem 2rem;
		padding-right: $global-gutter;
	}
	
}

.a-cta {
	
	&__textureOverlay {
		position: absolute;
			top: 0;
			left: 0;
		z-index: 3;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: left top;
		opacity: 0;
	}
	&__bgTexture {
		position: absolute;
			top: 0;
			left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center center;
	}
	
}

@media only screen and (min-width: 640px) {
	
	.m-cta {
		
		&__testimonialContainer {
			
			position: absolute;
				top: 3rem;
				right: 0rem;
			z-index: 4;
			width: 45%;
			background: $green;
			padding: 2rem 2rem;
			padding-right: $global-gutter;
			
		}
		
	}
	
}

@media only screen and (min-width: 1024px) {
	
	.a-cta {
		
		&__textureOverlay {
			opacity: 1;
		}
		
	}
	
}