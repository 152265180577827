.o-work {
	
	&__grid {
		background: $black;
		padding: 4rem 4rem;
		padding-top: 7rem;
		padding-bottom: 8rem;
		position: relative;
		
		&:after {
			display: block;
			content: "";
			position: absolute;
				bottom: 0;
				left: 50%;
			width: calc(100% - 24vw);
			transform: translateX(-50%);
			height: 2px;
			background: #fff;
		}
	}
	
}

.m-work {
	
	&__info {
		position: absolute;
			top: 0;
			right: 0;
		z-index: 4;
		width: 100%;
		height: 100%;
		background: #fff;
		overflow-y: scroll;
		transform: translateY(-100%);
		transition: all .5s $easeOutCubic;
		
		&.-is-active {
			transform: translateY(0%);
		}
	}
	
}

.a-work {
	
	&__bg {
		position: absolute;
			top: 0;
			left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center center;
	}
	&__overlay {
		position: absolute;
			top: 0;
			left: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		background: #000;
		opacity: .2;
	}
	
	&__infoHeader {
		background: $green;
		padding: 2rem 3rem;
	}
	&__infoBody {
		padding: 3rem;
		overflow-y: scroll;
	}
	
	&__overlaidTrigger {
		position: absolute;
			bottom: 2rem;
			left: 2rem;
		z-index: 3;
		width: calc(50% - 4rem);
	}
	&__trigger {
		background: #000;
		padding-top: 2rem;
	}
	
	&__cross {
		transition: all .2s $easeOutCubic;
		cursor: pointer;
		
		&:hover {
			opacity: .7;
		}
		&.-is-active {
			transform: rotate(45deg);
		}
	}
	
}


.m-workContainer {
	
	&__featured {
		position: relative;
		padding-bottom: 50%;
		overflow: hidden;
	}
	
	&__short {
		position: relative;
		padding-bottom: 75%;
		overflow: hidden;
	}
	
	&__square {
		position: relative;
		padding-bottom: 100%;
		overflow: hidden;
	}
	
	&__double {
		position: relative;
		padding-bottom: 50%;
		overflow: hidden;
	}
	
}

@media only screen and (max-width: 640px) {
	
	.m-workContainer {
		
		&__square {
			padding-bottom: 150%;
		}
		
	}
	
}

@media only screen and (min-width: 1024px) {
	
	.m-work {
		
		&__info {
			
			&.-half {
				width: 50%;
			}
			
		}
		
	}

}