.D\(b\) {
  display: block;
}
.D\(ib\) {
  display: inline-block;
}
.Fz\(1\.4rem\) {
  font-size: 1.4rem;
}
.Fz\(1\.5rem\) {
  font-size: 1.5rem;
}
.Fz\(10vw\) {
  font-size: 10vw;
}
.Fz\(11vw\) {
  font-size: 11vw;
}
.Fz\(13vw\) {
  font-size: 13vw;
}
.Fz\(2\.6rem\) {
  font-size: 2.6rem;
}
.Fz\(3rem\) {
  font-size: 3rem;
}
.Fz\(4\.5vw\) {
  font-size: 4.5vw;
}
.Fz\(8\.5vw\) {
  font-size: 8.5vw;
}
.Fz\(8vw\)\! {
  font-size: 8vw !important;
}
.Fz\(9\.6vw\) {
  font-size: 9.6vw;
}
.Mx\(a\) {
  margin-left: auto;
  margin-right: auto;
}
.Mt\(2\.5rem\) {
  margin-top: 2.5rem;
}
.Mt\(2rem\) {
  margin-top: 2rem;
}
.Mt\(3rem\) {
  margin-top: 3rem;
}
.Mend\(1rem\) {
  margin-right: 1rem;
}
.Mb\(0rem\) {
  margin-bottom: 0rem;
}
.Mb\(1\.5rem\) {
  margin-bottom: 1.5rem;
}
.Mb\(1rem\) {
  margin-bottom: 1rem;
}
.Mb\(2rem\) {
  margin-bottom: 2rem;
}
.Mb\(3rem\) {
  margin-bottom: 3rem;
}
.Mb\(3rem\)\! {
  margin-bottom: 3rem !important;
}
.Mb\(5rem\) {
  margin-bottom: 5rem;
}
.Mstart\(1rem\) {
  margin-left: 1rem;
}
.Maw\(30em\) {
  max-width: 30em;
}
.Op\(0\.5\) {
  opacity: 0.5;
}
.Px\(5vw\) {
  padding-left: 5vw;
  padding-right: 5vw;
}
.Py\(6rem\) {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.Pend\(20\%\) {
  padding-right: 20%;
}
.Pb\(2rem\) {
  padding-bottom: 2rem;
}
.Pstart\(1rem\) {
  padding-left: 1rem;
}
.Pos\(r\) {
  position: relative;
}
.Ta\(c\) {
  text-align: center;
}
.Z\(2\) {
  z-index: 2;
}
@media screen and (max-width: 640px) {
  .Fz\(\.8rem\)--sm {
    font-size: .8rem;
  }
  .Fz\(\.9rem\)--sm {
    font-size: .9rem;
  }
  .Fz\(1\.2rem\)--sm {
    font-size: 1.2rem;
  }
  .Fz\(1rem\)--sm {
    font-size: 1rem;
  }
  .Fz\(2\.5rem\)--sm {
    font-size: 2.5rem;
  }
  .Fz\(2rem\)--sm {
    font-size: 2rem;
  }
  .Mb\(3rem\)--sm {
    margin-bottom: 3rem;
  }
  .Mah\(800px\)\!--sm {
    max-height: 800px !important;
  }
  .Px\(0px\)\!--sm {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .Px\(10vw\)\!--sm {
    padding-left: 10vw !important;
    padding-right: 10vw !important;
  }
  .Py\(10vw\)\!--sm {
    padding-top: 10vw !important;
    padding-bottom: 10vw !important;
  }
  .Pt\(10rem\)\!--sm {
    padding-top: 10rem !important;
  }
}
@media screen and (min-width: 640px) {
  .Fz\(3\.5vw\)--md {
    font-size: 3.5vw;
  }
  .Fz\(7\.5vw\)--md {
    font-size: 7.5vw;
  }
  .Fz\(7vw\)--md {
    font-size: 7vw;
  }
  .Mb\(0rem\)\!--md {
    margin-bottom: 0rem !important;
  }
  .Mb\(2rem\)--md {
    margin-bottom: 2rem;
  }
  .Pt\(12rem\)--md {
    padding-top: 12rem;
  }
  .Ta\(end\)--md {
    text-align: right;
  }
}
@media screen and (min-width: 1024px) {
  .Fz\(12vw\)--lg {
    font-size: 12vw;
  }
  .Fz\(2\.5vw\)--lg {
    font-size: 2.5vw;
  }
  .Fz\(5\.5vw\)--lg {
    font-size: 5.5vw;
  }
  .Fz\(9vw\)--lg {
    font-size: 9vw;
  }
  .Op\(1\)--lg {
    opacity: 1;
  }
  .Pt\(15rem\)--lg {
    padding-top: 15rem;
  }
}
