.o-cards {
	
	&__gridContainer {
		position: relative;
		padding: 6rem $global-gutter;
	}
	&__grid {
		position: relative;
		z-index: 2;
	}
	
}

.a-cards {
	
	&__bgTexture {
		opacity: 0;
		position: absolute;
			top: 0;
			left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: left top;
	}
}

.m-card {
	
	&__header {
		padding: 2rem;
		position: relative;
		
		&:after {
			display: block;
			content: "";
			position: absolute;
				bottom: 0;
				left: 0rem;
			width: 100%;
			height: 2px;
			background: $black;
		}
	}
	
	&__body {
		padding: 2rem;
	}
	
}

.a-card {}

@media only screen and (min-width: 640px) {
	
	.o-cards {
		&__grid {
			padding-top: 10rem;
		}
	}
	
}

@media only screen and (min-width: 1024px) {
	
	.a-cards {
		&__bgTexture {
			opacity: 1;
		}
	}
	.o-card {
		padding-top: 0rem;
		
		&.-first {
			
			.m-card__body {
				border-right: 2px solid $black;
			}
			.m-card__header:after {
				left: auto;
				right: 0;
				width: calc(100% + 5vw);
			}
			
		}
		&.-last {
			
			.m-card__body {
				border-left: 2px solid $black;
			}
			.m-card__header:after {
				width: calc(100% + 5vw);
			}
			
		}
			
	}
	
}