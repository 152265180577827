.o-mainFooter {
	background: $green;
	padding: 3rem $global-gutter;
}

.a-mainFooter {
	
	&__logo {
		width: 8vw;
		max-width: 100px;
		min-width: 100px;
	}
	
}

@media only screen and (min-width: 640px) {
	
	
	
}