@mixin font(
$style: normal,
$variant: normal,
$weight: 400,
$size: 1em,
$line-height: 1.2em,
$font: 'Monosten',
$color: $body-font-color) {
	
	font-family: $font, sans-serif;
	font-style: $style;
	font-variant: $variant;
	font-weight: $weight;
	line-height: $line-height;
	font-size: $size;
	color: $color;
	text-transform: none;
}

@mixin colors() {
	
	.-color {
		@each $name, $color in $colors {
			&-#{$name} {
				color: $color;
				
				& > p {
					color: $color;
				}
			}
		}
	}
	
}