.o-team {
	
	&__grid {
		background: $black;
		padding: 5rem 5vw;
	}
	
}

.m-team {
	
	&__container {
		margin-top: 5rem;
		margin-bottom: 5rem;
	}
	
}

.a-team {
	
	
	&__headshotContainer {
		padding-left: 3rem;
		padding-right: 3rem;
		padding-bottom: 2rem;
		border-bottom: 2px solid #fff;
	}
	&__headshot {
		width: 15vw;
	}
	
	&__name {
		padding-bottom: 2rem;
		padding-right: 3rem;
		border-right: 2px solid #fff;
		border-bottom: 2px solid #fff;
	}
	
	&__info {
		padding-bottom: 2rem;
		border-bottom: 2px solid #fff;
	}
	
	
}

@media only screen and (max-width: 640px) {
	
	.a-team {
		
		&__headshotContainer,
		&__name,
		&__info {
			border: 0px !important;
		}
		
		&__headshotContainer {
			padding-left: 0rem;
		}
		
		&__headshot {
			width: 40vw;
		}
		
		
	}
	
}