.o-mainHeader {
	
	position: relative;
	z-index: 200;
	width: 100%;
	
}

.m-mainHeader {
	
	&__nav {
		position: absolute;
			top: 0;
			left: 0;
		width: 100%;
		padding: 2rem $global-gutter;
	}
	&__linkContainer {
		text-align: center;
	}
	
}

.a-mainHeader {
	
	&__logo {
		width: 35vw;
		max-width: 250px;
	}
	
}

@media only screen and (min-width: 640px) {
	
	.a-mainHeader {
		
		&__logo {
			width: 25vw;
			max-width: 250px;
		}
		
	}
	
}