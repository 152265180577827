.a-link {
	
	&__nav {
		@include font(
			
			$font: 'Monosten',
			$size: 1.05rem,
			$line-height: 1.5em,
			$color: $white
			
		);
		letter-spacing: .096em;
		position: relative;
		padding-bottom: .2rem;
		
		&:after {
			display: block;
			content: '';
			position: absolute;
				bottom: 0;
				left: 0;
			width: 100%;
			height: 1px;
			background: #fff;
			
			transform: translateY(2px);
			opacity: 0;
			transition: all .3s $easeOutCubic;
		}
		
		&:hover {
			color: #fff;
			&:after {
				transform: translateY(0px);
				opacity: 1;
			}
		}
		&:focus {
			color: #fff;
		}
	}
	
}

.m-button {
	
	@include font(
			
		$font: 'Monosten',
		$size: 1.05rem,
		$line-height: 1.5em,
		$color: $white
		
	);
	letter-spacing: .096em;
	text-transform: uppercase;
	padding: .8rem 2rem;
	border: 1px solid $white;
	position: relative;
	overflow: hidden;
	display: inline-block;
	cursor: pointer;
	
	&:after {
		display: block;
		content: '';
		position: absolute;
			z-index: 1;
			top: 50%;
			left: 0;
		width: 150%;
		padding-bottom: 250%;
		background: #fff;
		transform: translate(-130%, -50%) rotate(45deg);
			transform-origin: left;
		transition: all .5s $easeOutCubic;
	}
	
	
	&:hover {
		
		color: $black;
		&:after {
			transform: translate(-20%, -50%) rotate(45deg);
		}
		
	}
	
	&:focus {
		color: $black;
	}
	
	&.-black-button {
		color: $black;
		border-color: $black;
	}
	
	&.-green-fill {
		&:after {
			background: $green;
		}
	}
	
	
}

.a-button {
	
	&__content {
		position: relative;
			z-index: 2;
		color: inherit;
		transition: all .5s $easeOutCubic;
	}
	
}